import listAPI from "@/api/listAPI.js";
import alerts from "../common/notifications";
import patientAPI from "@/api/patientAPI.js";
import notificationsAPI from "@/api/notificationsAPI.js";
import reportsAPI from "@/api/reportsAPI.js";
import Header from "@/components/Header.vue";
import router from "@/router/index.js";
import store from "@/store/index.js";
import permissions from "@/api/permissions.js";

export default {
  name: "patientsearch",
  components: {
    Header,
    alerts,
  },
  data: () => ({
    formData: {
      first_name: null,
      last_name: null,
      city: null,
      state_id: null,
      phone: null,
      email: null,
      doctor_id: null,
      diagnosis_id: null,
      remote_services_id: null,
      patient_status_id: null,
      show_scheduled: "T",
      show_missed: "T",
    },
    vrSelectAll: false,
    errorType: "",
    message: "",
    snackbar: false,
    loading: false,
    vrChannel: null,
    vrLetterType: null,
    apsNotification: { mail_content: "", channel: {}, letter_type: {} },
    //apsNotification: {},
    enrollType: [],

    //Doctor-------------
    model_doctor: null,
    isLoadingDoctor: false,
    searchDoctor: null,

    //Diagnosis-----------
    model_diagnosis: null,
    isLoadingDiagnosis: false,
    searchDiagnosis: null,

    //Device Mpodel-------
    vrPatientDevice: {},
    vrCarrierPrimary: null,
    isLoadingModel: false,
    vrKeyWordCarrierPrimary: null,

    vrState: [],
    vrStatus: [],
    vrRemoteService: [],
    vrDoctors: [],
    vrDiagnosis: [],
    vrEventType: null,
    vrRemoteServiceList: null,
    loadingNotify: false,
    loadingNotifyAll: false,
    headers: [
      { sortable: false, text: "Select", value: "selected", width: "1%" },
      {
        sortable: false,
        text: "PATIENT ID",
        value: "patient_code",
        width: "4%",
      },
      { sortable: false, text: "AFFIL ID", value: "affiliate_id", width: "5%" },

      { sortable: false, text: "NAME", value: "full_name", width: "10%" },
      { sortable: false, text: "EVENT DATE", value: "event_date", width: "5%" },
      { sortable: false, text: "EVENT TYPE", value: "event_type", width: "5%" },
      {
        sortable: false,
        text: "REMOTE SERVICES",
        value: "remote_services",
        width: "10%",
      },
      { sortable: false, text: "EMAIL", value: "email", width: "7%" },
      { sortable: false, text: "PHONE", value: "phone", width: "5%" },

      { sortable: false, text: "DOCTOR", value: "doctor", width: "12%" },
      { sortable: false, text: "DIAGNOSIS", value: "diagnosis", width: "12%" },
      {
        sortable: false,
        text: "CHANNEL TYPE",
        value: "channel_type",
        width: "8%",
      },
      {
        sortable: false,
        text: "LETTER TYPE",
        value: "letter_type",
        width: "8%",
      },
      { sortable: false, text: "SENT TO", value: "sent_to", width: "8%" },
    ],

    totalRecords: 0,
    tblLoading: false,
    options: {},
    vrPatients: [],

    exportLoadingLabel: false,
    exportLoadingExcel: false,
    exportLoadingCSV: false,
    exportLoadingPDF: false,

    disableExport: false,

    isPostback: false,

    reportsURL: {
      label: "exportDocuments/exportToLabel",
      csv: "exportDocuments/exportToCsv",
      excel: "exportDocuments/exportToExcel",
      pdf: "exportDocuments/exportToPdf",
    },

    //---------------------------------------------------
    //Used for permission handling
    //---------------------------------------------------
    permissions: {},
    //---------------------------------------------------
  }),
  mounted() {
    store.state.patientId = null;
    store.state.patientInfo.first_name = null;
    store.state.patientInfo.last_name = null;

    // const tiles = document.querySelectorAll(".chk_patient");
    // tiles.forEach(tile => tile.addEventListener('click', this.chkClick))
  },
  computed: {
    vrNotify() {
      return store.state.notify;
    },
  },

  methods: {
    addPatient() {
      store.state.patientId = null;
      store.state.patientInfo.first_name = null;
      store.state.patientInfo.last_name = null;
      store.state.redirectURL = "search";

      router.push("patient");
    },
    loadPatients() {
      if (!this.isPostback) {
        this.isPostback = true;
        this.disableExport = true;
        this.vrPatients = [];
        this.totalRecords = 0;
        return false;
      }
      this.tblLoading = true;
      this.formData.pagination = this.options;
      this.formData.exportType = null;
      this.vrSelectAll = false;

      notificationsAPI
        .getSearchresultnotification(this.formData)
        .then((response) => {
          //Show message if status = false
          if (!response.data.status) {
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }

          if (response.data.results !== null) {
            this.vrPatients = response.data.results.Patients;
            this.totalRecords = response.data.results.PatientCount.total_count;
            if (this.totalRecords > 0) {
              this.disableExport = false;
            } else {
              this.disableExport = true;
            }
          } else {
            this.vrPatients = [];
            this.totalRecords = 0;
            this.disableExport = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.disableExport = true;
        })
        .finally(() => {
          this.tblLoading = false;
        });
    },
    searchPatients: function (e) {
      e.preventDefault();

      this.options.page = 1;

      //This method is used to load data
      this.loadPatients();

      this.vrSelectAll = false;
    },
    selectPatient(item) {
      //Set the patient Id for other screens/flow
      store.state.patientId = item.patient_id;

      store.state.redirectURL = "notify-patient";
      router.push("patient");
    },

    exportLabel() {
      this.formData.exportType = "LABEL";
      this.exportLoadingLabel = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToLabel",
          this.reportsURL.label,
          this.formData,
          "Patient-Details-Label.xlsx",
          this.exportLoadingLabel
        )
        .then(() => {
          this.exportLoadingLabel = false;
        });
    },
    exportCSV() {
      this.formData.exportType = "CSV";
      this.exportLoadingCSV = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToCsv",
          this.reportsURL.csv,
          this.formData,
          "Patient-Details.csv"
        )
        .then(() => {
          this.exportLoadingCSV = false;
        });
    },
    exportExcel() {
      this.formData.exportType = "EXCEL";
      this.exportLoadingExcel = true;
      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToExcel",
          this.reportsURL.excel,
          this.formData,
          "Patient-Details.xlsx"
        )
        .then(() => {
          this.exportLoadingExcel = false;
        });
    },
    exportPDF() {
      this.formData.exportType = "PDF";
      this.exportLoadingPDF = true;

      reportsAPI
        .downloadReport(
          //"exportDocuments/exportToPdf",
          this.reportsURL.pdf,
          this.formData,
          "Patient-Details.pdf"
        )
        .then(() => {
          this.exportLoadingPDF = false;
        });
    },

    //-------------------------------------------------------
    //Search Diagnosis
    //-------------------------------------------------------
    searchInsurer(val, vrControlName, vrLoadingControlName) {
      // Items have already been requested
      if (this[vrLoadingControlName]) return; //Return

      if (val === null) return; //Return

      this[vrLoadingControlName] = true; //initiate loading

      listAPI
        .getDeviceModelList({ SearchString: val })
        .then((response) => {
          if (response.data.results !== null)
            this.vrCarrierPrimary = response.data.results.Devices;
          else this.vrCarrierPrimary = [];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this[vrLoadingControlName] = false;
        });

      this[vrLoadingControlName] = false;
    }, //End searchDiagnosis
    //-------------------------------------------------------

    clearAllFields() {
      this.formData = { show_scheduled: "T", show_missed: "T" };

      this.options.page = 1;
      this.isPostback = false;
      this.loadPatients();

      this.autoEnrolment = {};

      this.vrSelectAll = false;
    },

    addTask(vrValue, index) {
      let ltAllSelected = true;

      this.vrPatients[index].selected = vrValue;
      if (!vrValue) {
        this.vrSelectAll = vrValue;
      }

      if (!this.vrSelectAll && vrValue) {
        this.vrPatients.forEach((item) => {
          if (!item.selected && ltAllSelected != null) {
            ltAllSelected = null;
          }
        });

        this.vrSelectAll = ltAllSelected;
      }
      return false;
    },
    selectAll(vrValue) {
      this.vrPatients.forEach((item) => {
        item.selected = vrValue;
      });
      return false;
    },
    chkClick(e) {
      alert(e);
      console.log(e);
      e.preventDefault();
      return false;
    },
    row_bg_color(item) {
      return item.row_color;
    },
    sendNotifications(notifyAll) {
      this.apsNotification.notify_all = notifyAll;

      //Validations--------------------------------------------------
      let isValid = true;

      let ltChannel = this.apsNotification.channel;
      let ltLetter_type = this.apsNotification.letter_type;
      //let ltRemote_service_id = this.apsNotification.remote_service_id;
      //let ltEvent_type_id = this.apsNotification.event_type_id;

      if (ltChannel == null || ltChannel.lookup_id == null) {
        isValid = false;
      }
      if (ltLetter_type == null || ltLetter_type.lookup_id == null) {
        isValid = false;
      }

      // if (ltRemote_service_id == null || ltEvent_type_id == null) {
      //   isValid = false;
      // }

      if (!isValid) {
        this.apsNotification.mail_content = "";
        //Show required error message
        this.vrNotify.message = store.state.required.message;
        this.vrNotify.type = store.state.required.type;
        this.vrNotify.visible = store.state.required.visible;
        return false;
      }

      if (notifyAll != "y") {
        if (
          this.vrPatients.filter((item) => {
            return item.selected == true;
          }).length == 0
        ) {
          //Show required error message
          this.vrNotify.message =
            "Please select at least one patient to notify.";
          this.vrNotify.type = store.state.required.type;
          this.vrNotify.visible = store.state.required.visible;
          //this.loading = false;
          return false;
        }
        this.loadingNotify = true;
      } else {
        this.loadingNotifyAll = true;
      }
      //-------------------------------------------------------------

      let ltNotification = {
        list: this.vrPatients,
        message: this.apsNotification,
      };
      notificationsAPI
        .sendNotifications(ltNotification)
        .then((response) => {
          if (response.data !== null) {
            //this.vrDoctors = response.data.results.Doctors;
            this.vrNotify.type = response.data.error_type;
            this.vrNotify.message = response.data.message;
            this.vrNotify.visible = true;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loadingNotifyAll = false;
          this.loadingNotify = false;
        });
    },
    handleNotification() {
      let isValid = true;

      let ltChannel = this.apsNotification.channel;
      let ltLetter_type = this.apsNotification.letter_type;
      let ltRemote_service_id = this.apsNotification.remote_service_id;
      let ltEvent_type_id = this.apsNotification.event_type_id;

      if (ltChannel == null || ltChannel.lookup_id == null) {
        isValid = false;
      }
      if (ltLetter_type == null || ltLetter_type.lookup_id == null) {
        isValid = false;
      }

      if (ltRemote_service_id == null || ltEvent_type_id == null) {
        isValid = false;
      }

      if (!isValid) {
        this.apsNotification.mail_content = "";
        return false;
      }

      this.apsNotification.called_from = "notification";

      patientAPI
        .getNotificationText(this.apsNotification)
        .then((response) => {
          if (response.data.results !== null) {
            if (response.data.status == false) {
              this.vrNotify.type = response.data.error_type;
              this.vrNotify.message = response.data.message;
              this.vrNotify.visible = true;
            }

            this.apsNotification.mail_content =
              response.data.results.mail_content;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {});
    },
    allow_selection(item) {
      let ltChannel = this.apsNotification.channel;

      //Case when channel is not selected
      if (ltChannel.lookup_value == null) {
        item.is_allow_selection = false;
        item.selected = false;
      }

      //Case for emails
      if (ltChannel.lookup_value == "E") {
        if (item.is_allow_email == "Y") {
          //enable the checkbox for selection
          item.is_allow_selection = true;
        } else {
          //Disable checkbox for selection
          item.is_allow_selection = false;
          item.selected = false;
        }
      } else if (ltChannel.lookup_value == "T") {
        //Case for Mobile SMS/Text
        if (item.is_allow_sms == "Y") {
          //enable the checkbox for selection
          item.is_allow_selection = true;
        } else {
          //Disable checkbox for selection
          item.is_allow_selection = false;
          item.selected = false;
        }
      } else if (ltChannel.lookup_value == "P") {
        //Case for print
        item.is_allow_selection = true;
      }
    },
    updateNotificationText(evt) {
      this.apsNotification.mail_content = evt.target.innerHTML;
    },
  },
  watch: {
    options: {
      handler() {
        this.loadPatients();
      },
      deep: true,
    },
    searchDoctor(val) {
      // Items have already been requested
      if (this.isLoadingDoctor) return; //Return

      this.isLoadingDoctor = true; //initiate loading

      if (val !== null) {
        listAPI
          .getDoctorsList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this.vrDoctors = response.data.results.Doctors;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDoctor = false;
          });

        // listAPI @VVVVV
        //   .getEventTypeList()
        //   .then((response) => {
        //     if (response.data.results !== null)
        //       this.vrEventType = response.data.results.Event_Type;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {});

        // listAPI
        //   .getFrequencyUnitList()
        //   .then((response) => {
        //     if (response.data.results !== null)
        //       this.vrFrequencyUnit = response.data.results.Lookup;
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {});
      }

      this.isLoadingDoctor = false;
    },
    searchDiagnosis(val) {
      // Items have already been requested
      if (this.isLoadingDiagnosis) return; //Return

      this.isLoadingDiagnosis = true; //initiate loading

      if (val !== null) {
        listAPI
          .getDiagnosisList({ SearchString: val })
          .then((response) => {
            if (response.data.results !== null)
              this.vrDiagnosis = response.data.results.Diagnosis;
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.isLoadingDiagnosis = false;
          });
      }

      this.isLoadingDiagnosis = false;
    },
    vrKeyWordCarrierPrimary(val) {
      if (val == null) {
        this.vrInsurancePrimary = {};
        return;
      }
      this.searchInsurer(val, "vrCarrierPrimary", "isLoadingCarrierPrimary");
    },
  },
  async beforeMount() {
    await Promise.all([
      listAPI
        .getPatientSearchMasters()
        .then((response) => {
          this.vrState = response.data.results.States;
          this.vrStatus = response.data.results.Status;
          this.vrRemoteService = response.data.results.RemoteServices;
        })
        .catch((error) => {
          console.log(error);
        }),
      listAPI
        .getEventTypeList()
        .then((response) => {
          if (response.data.results !== null)
            this.vrEventType = response.data.results.Event_Type;
        })
        .catch((err) => {
          console.log(err);
        }),
      //CHANNEL_TYPE
      listAPI
        .getLookup("CHANNEL_TYPE,NOTIFICATION")
        .then((response) => {
          this.vrChannel = response.data.results.Lookup;
        })
        .catch((error) => {
          console.log(error);
        }),
      listAPI
        .getLookup("LETTER_TYPE")
        .then((response) => {
          this.vrLetterType = response.data.results.Lookup;
        })
        .catch((error) => {
          console.log(error);
        }),
      listAPI
        .getRemoteServiceList()
        .then((response) => {
          if (response.data.results !== null)
            this.vrRemoteServiceList = response.data.results.Remote_Service;
        })
        .catch((err) => {
          console.log(err);
        }),
    ]);
  },
  created() {
    //Get permissions for logged in user
    this.permissions = permissions.getPermissions("search");
  },
};
