import apiClient from "./apiClient";
//import constant from './constants';

export default {
  getSearchresultnotification(data) {
    return apiClient.post("notification/getsearchresultnotification", data);
  },
  sendNotifications(data) {
    return apiClient.post("notification/send", data);
  },
};
